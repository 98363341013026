<template>
  <div>
    <b-card>
      <b-form class="m-0" ref="formFilter" @submit.prevent="getData()">
        <div class="form-row justify-content-left align-items-center">
          <div class="col-md-2 ">
            <div class="form-group mb-md-0">
              <v-select
                label="title"
                required
                :searchable="false"
                v-model="filters.perPage"
                :options="perPageOptions"
                @input="setPerPageSelected"
                :clearable="false"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group mb-md-0">
              <input
                type="text"
                v-model="filters.company_name"
                class="form-control"
                placeholder="Nome fantasia"
              />
            </div>
          </div>

          <div class="col-md-1 offset-md-5">
            <button type="submit" class="btn btn-primary btn-block">
              <div v-if="submitedFilter">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <i class="bi bi-search cursor-pointer cursor"></i>
              </div>
            </button>
          </div>
        </div>
      </b-form>
    </b-card>
    <b-card no-body>
      <b-table
        sticky-header
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :busy="loading"
        empty-text="Nenhum registro encontrado"
        class="position-relative"
      >
        <template #head(invoiceStatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>

        <template #cell(city)="{ item }">
          <span v-if="item.city && item.state">
            {{ item.city }}/{{ item.state }}
          </span>
        </template>

        <template #cell(document)="{ item }">
          {{ item.document }}
        </template>

        <template #cell(actions)="data">
          <div
            class="d-flex-between"
            v-if="
              $can('Unidade Estratégica - Excluir', 'Financeiro - ADM') || 
              $can('Unidade Estratégica - Editar', 'Financeiro - ADM')
            "
          >
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              v-if="$can('Unidade Estratégica - Excluir', 'Financeiro - ADM')"
              @click="confirmDelete(data.item.uuid)"
            />

            <router-link
              :to="{
                name: 'strategic-units-edit',
                params: { uuid: data.item.uuid },
              }"
              v-if="$can('Unidade Estratégica - Editar', 'Financeiro - ADM')"
            >
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer cursor"
                size="16"
              />
            </router-link>
          </div>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              small
              class="align-middle"
              style="margin-right: 3px"
            ></b-spinner>
            <strong> carregando...</strong>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BForm,
  BSpinner,
} from "bootstrap-vue";

import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";

import CustomPaginateTable from "@/views/components/custom/PaginationTable.vue";

import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BForm,
    BTooltip,
    vSelect,
    CustomPaginateTable,
    StatisticCardVertical,
    BSpinner,
  },
  setup() {
    const statusOptions = [
      "Downloaded",
      "Draft",
      "Paid",
      "Partial Payment",
      "Past Due",
    ];

    return {
      statusOptions,
    };
  },
  data() {
    return {
      loading: false,
      filters: {
        company_name: "",
        state: "",
        document: "",
        currentPage: 1,
        perPage: 100,
      },
      dataItems: [],
      optionsStatus: [],
      submitedFilter: false,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      tableColumns: [
        {
          key: "company_name",
          label: "Nome",
          class: "text-left text-elipse-400",
        },
        {
          key: "created_at",
          label: "Cadastro",
          class: "text-center",
          thStyle: "width: 120px",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
    };
  },
  methods: {
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },

    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("StrategicUnit/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
    async getData() {
      this.loading = true;
      this.submitedFilter = true;
      this.$store
        .dispatch("StrategicUnit/all", this.filters)
        .then((res) => {
          if (res) {
            this.dataItems = res.data;
            this.rowsTable = res.data.length;
            this.totalRows = res.meta.total;
            this.filters.currentPage = res.meta.current_page;
          }
        })
        .finally(() => {
          this.submitedFilter = false;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getData();

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.per-page-selector {
  width: 90px;
}
</style>
