var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        [
          _c(
            "b-form",
            {
              ref: "formFilter",
              staticClass: "m-0",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.getData()
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "form-row justify-content-left align-items-center",
                },
                [
                  _c("div", { staticClass: "col-md-2 " }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c("v-select", {
                          attrs: {
                            label: "title",
                            required: "",
                            searchable: false,
                            options: _vm.perPageOptions,
                            clearable: false,
                          },
                          on: { input: _vm.setPerPageSelected },
                          model: {
                            value: _vm.filters.perPage,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "perPage", $$v)
                            },
                            expression: "filters.perPage",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("div", { staticClass: "form-group mb-md-0" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.company_name,
                            expression: "filters.company_name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", placeholder: "Nome fantasia" },
                        domProps: { value: _vm.filters.company_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.filters,
                              "company_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-1 offset-md-5" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-block",
                        attrs: { type: "submit" },
                      },
                      [
                        _vm.submitedFilter
                          ? _c(
                              "div",
                              [
                                _c("b-spinner", {
                                  attrs: { label: "Loading...", small: "" },
                                }),
                              ],
                              1
                            )
                          : _c("div", [
                              _c("i", {
                                staticClass:
                                  "bi bi-search cursor-pointer cursor",
                              }),
                            ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              busy: _vm.loading,
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u([
              {
                key: "head(invoiceStatus)",
                fn: function () {
                  return [
                    _c("feather-icon", {
                      staticClass: "mx-auto",
                      attrs: { icon: "TrendingUpIcon" },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(city)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.city && item.state
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(item.city) +
                              "/" +
                              _vm._s(item.state) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(document)",
                fn: function (ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.document) + " ")]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _vm.$can(
                      "Unidade Estratégica - Excluir",
                      "Financeiro - ADM"
                    ) ||
                    _vm.$can("Unidade Estratégica - Editar", "Financeiro - ADM")
                      ? _c(
                          "div",
                          { staticClass: "d-flex-between" },
                          [
                            _vm.$can(
                              "Unidade Estratégica - Excluir",
                              "Financeiro - ADM"
                            )
                              ? _c("feather-icon", {
                                  staticClass:
                                    "cursor-pointer cursor text-danger",
                                  attrs: { icon: "TrashIcon", size: "16" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmDelete(data.item.uuid)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.$can(
                              "Unidade Estratégica - Editar",
                              "Financeiro - ADM"
                            )
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "strategic-units-edit",
                                        params: { uuid: data.item.uuid },
                                      },
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "cursor-pointer cursor",
                                      attrs: { icon: "EditIcon", size: "16" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-primary my-2" },
                      [
                        _c("b-spinner", {
                          staticClass: "align-middle",
                          staticStyle: { "margin-right": "3px" },
                          attrs: { small: "" },
                        }),
                        _c("strong", [_vm._v(" carregando...")]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.filters.currentPage,
              "per-page": _vm.filters.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }